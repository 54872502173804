<template>
    <div v-if="modalOpen" class="overlay" @mousedown.self="handleCloseModal">
        <div class="modal__window">
            <button class="modal__close" @click="handleCloseModal">
                <XCircleIcon />
            </button>
            <div class="modal__header">
                <slot name="header"></slot>
            </div>
            <div class="modal__body">
                <slot name="body"></slot>
            </div>
            <div class="modal__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalContainer',
    props: {
        modalOpen: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        handleCloseModal() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: fixed;
    background: rgba(#000, 0.4);
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    &__window {
        position: relative;
        /*max-width: 760px;*/
        max-width: 930px;
        width: 100%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;
        background-color: #fff;
        border-radius: 6px;
    }

    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        transition: 0.2s;
        cursor: pointer;
        border-radius: 50%;
        background: #fff;

        &:hover {
            color: #fff;
            background: #4d4d4d;
        }
    }

    &__body {
        max-height: 670px;
        overflow: auto;
    }

    &__header {
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 15px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
